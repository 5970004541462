import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventAPIService } from 'projects/api-client/src/api/event.service';
import { OrganizationMembershipAPIService } from 'projects/api-client/src/api/organization-membership.service';
import { AuthService, UserContext } from 'projects/api-client/src/auth/auth.service';
import { ApplicationPermission } from 'projects/api-client/src/models/common/ApplicationPermission';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Event } from "src/app/shared/models/event/event";
import { Organization } from '../../models/organization/organization';
import { OrganizationSummary } from '../../models/organization/organization-summary';
import { NavigationService, MenuMode } from '../../services/navigation-service/navigation.service';
import { SortService } from '../../services/sort-service/sort.service';
import { AssetFileService } from '../../services/asset-file-service/asset-file.service';

@Component({
    selector: 'user-space-selector',
    templateUrl: './user-space-selector.component.html'
})
export class UserSpaceSelectorComponent implements OnInit {

    ApplicationPermission = ApplicationPermission;
    NavigationService = NavigationService;
    MenuMode = MenuMode;

    currentEvent?: Event;
    events: Event[] = [];

    currentOrganization?: Organization;
    organizations: OrganizationSummary[] = [];

    currentMenuMode: MenuMode;
    userContext: UserContext|null;

    constructor(authService: AuthService,
        eventAPIService: EventAPIService,
        organizationMembershipAPIService: OrganizationMembershipAPIService,
        public assetFileService: AssetFileService,
        navigationService: NavigationService,
        private dialog: MatDialog) {
        authService.currentUserContextChanged
            .pipe(takeUntil(this.$destroy))
            .subscribe(async currentUserContext => {
                this.userContext = currentUserContext;
                if (currentUserContext) {
                    this.events = (await eventAPIService.listEventsByUser().toPromise())
                        .map(x => Event.from(x))
                        .filter(x => x.isActiveForCurrentUser)
                        .sort(SortService.getLocalizedKeyStringComparator("name"));
                    this.organizations = (await organizationMembershipAPIService.getByMembership().toPromise())
                        .map(x => OrganizationSummary.from(x))
                        .sort(SortService.getStringComparator("name"));
                } else {
                    this.events = [];
                    this.organizations = [];
                }
            });

        navigationService.menuChanged
            .pipe(takeUntil(this.$destroy))
            .subscribe(({ menuMode, currentEvent, currentOrganization }) => {
                this.currentMenuMode = menuMode;
                this.currentEvent = currentEvent;
                this.currentOrganization = currentOrganization;
            });
    }

    ngOnInit(): void {
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}
