import { Component } from "@angular/core";
import { ClientSettingsService } from "src/app/shared/services/client-settings-service/client-settings.service";
import { MenuMode, NavigationService } from "src/app/shared/services/navigation-service/navigation.service";

@Component({
    templateUrl: './trial-expired.component.html'
})
export class TrialExpiredComponent {
    NavigationService = NavigationService;
    salesEmail: string | undefined;
    
    constructor(navigationService: NavigationService, clientSettingsService: ClientSettingsService) {
        this.salesEmail = clientSettingsService.getValueInLocale(clientSettingsService.clientSettings?.sales_email);
        clientSettingsService.clientSettingsChange.subscribe((clientSettings: any) => {
            this.salesEmail = clientSettingsService.getValueInLocale(clientSettings?.sales_email);
        });  
        navigationService.setMenu(MenuMode.EMPTY);
        navigationService.showLayout = true;
    }
}