import { Component } from '@angular/core';
import { ClientSettingsService } from '../../services/client-settings-service/client-settings.service';

@Component({
    templateUrl: './order-sms-dialog.component.html'
})
export class OrderSmsDialogComponent {
    salesEmail: string | undefined;
    constructor(clientSettingsService: ClientSettingsService) {
        this.salesEmail = clientSettingsService.getValueInLocale(clientSettingsService.clientSettings?.sales_email);
        clientSettingsService.clientSettingsChange.subscribe((clientSettings: any) => {
            this.salesEmail = clientSettingsService.getValueInLocale(clientSettings?.sales_email);
        });
    }
}
