import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler, Injector, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, Inject } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { setAppInjector } from './app-injector';
import * as moment from 'moment';
import 'moment-timezone';
import { SharedModule } from './shared/shared.module';
import { AppSettingsService } from './shared/services/app-settings/app-settings.service';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { ApiCLientModule } from 'projects/api-client/src/api.module';
import { SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { AngularMaterialModule } from './angular-material.module';
import { AuthService, ApiClientConfiguration, API_CLIENT_CONFIGURATION, UserContext } from 'projects/api-client/src/public-api';
import { MatomoService } from './shared/services/matomo-service/matomo-service';
import { GoogleMapsModule } from '@angular/google-maps';
import { ScriptLoaderService } from './shared/services/script-loader-service/script-loader.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfflineModule } from '@ngx-pwa/offline';
import { AccessDeniedComponent } from './pages/misc/access-denied/access-denied.component';
import { MaintenanceComponent } from './pages/misc/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/misc/not-found/not-found.component';
import { ProfileDeletedComponent } from './pages/misc/profile-deleted/profile-deleted.component';
import { HomeRedirectComponent } from './home-redirect.component';
import { ApplicationSettingsService } from './shared/services/application-settings-service/application-settings.service';
import { ColorSchemeService } from './shared/services/color-scheme/color-scheme.service';
import { RouterHistoryService } from './shared/services/router-history-service/router-history.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AssetServiceConfiguration, ASSET_SERVICE_CONFIGURATION } from './shared/services/asset-file-service/asset-file.service';
import { LanguageService } from './shared/services/language-service/language.service';
import { TrialExpiredComponent } from './pages/misc/trial-expired/trial-expired.component';
import { SubscriptionExpiredComponent } from './pages/misc/subscription-expired/subscription-expired.component';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor() { }

    _sentryEnabled = false;
    public set sentryEnabled(value: boolean) {
        this._sentryEnabled = value;
    }

    handleError(error: { message: string; originalError: any; }) {

        console.error(error);

        // Webapp has been updated? reload the current page
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }

        // Else, log to sentry
        else {
            Sentry.captureException(error.originalError || error);
        }
    }
}

export function init(appSettingsService: AppSettingsService,
    matomoService: MatomoService,
    scriptLoaderService: ScriptLoaderService,
    apiClientConfiguration: ApiClientConfiguration,
    applicationSettingsService: ApplicationSettingsService,
    titleService: Title,
    assetServiceConfiguration: AssetServiceConfiguration) {
    return () => appSettingsService.getSettings().then(async settings => {
        environment.settings = settings;
        appSettingsService.checkMaintenance(environment.settings);
        apiClientConfiguration.basePath = settings.MainApiBaseUrl;
        assetServiceConfiguration.baseUrl = settings.AssetsBaseUrl;
        await applicationSettingsService.refresh();

        // Inject theme colors
        applicationSettingsService.injectThemeColors();

        // Set page title
        titleService.setTitle(applicationSettingsService.settings.application_identity.name.text);

        // Setup Matomo
        if (applicationSettingsService.settings.matomo.enable) {
            matomoService.init(applicationSettingsService.settings.matomo.instance_url, applicationSettingsService.settings.matomo.main_web_app_site_id);
        }

        // Initialize Google Map API
        scriptLoaderService.loadScript(`https://maps.googleapis.com/maps/api/js?key=${applicationSettingsService.settings.google_maps.api_key}&libraries=places&callback=Function.prototype`);

        // Setup Sentry
        Sentry.init({
            dsn: environment.settings.SentryDsn || undefined,
            environment: environment.settings.Environment,
            release: environment.settings.GitRevision,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 0.1,
            tracePropagationTargets: [environment.settings.MainApiBaseUrl],
        });
    });
}

export function socialLoginConfigFactory(applicationSettingsService: ApplicationSettingsService) {
    const config: SocialAuthServiceConfig = { providers: [] };
    if (applicationSettingsService.settings.google_login.enable) {
        config.providers.push({
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(applicationSettingsService.settings.google_login.client_id)
        });
    }
    if (applicationSettingsService.settings.facebook_login.enable) {
        config.providers.push({
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(applicationSettingsService.settings.facebook_login.client_id)
        });
    }
    return config;
}

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        AccessDeniedComponent,
        TrialExpiredComponent,
        SubscriptionExpiredComponent,
        MaintenanceComponent,
        ProfileDeletedComponent,
        HomeRedirectComponent
    ],
    imports: [
        AngularMaterialModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ApiCLientModule.forRoot(),
        SharedModule.forRoot(),
        SocialLoginModule,
        GoogleMapsModule,
        HammerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        // Automatic redirect to offline page if no connection when PWA
        OfflineModule.forRoot({
            routeOffline: '/pwa/offline-page'
        })
    ],
    bootstrap: [AppComponent],
    providers: [
        AppSettingsService,
        MatomoService,
        ScriptLoaderService,
        {
            provide: APP_INITIALIZER, useFactory: init, deps: [
                AppSettingsService,
                MatomoService,
                ScriptLoaderService,
                API_CLIENT_CONFIGURATION,
                ApplicationSettingsService,
                Title,
                ASSET_SERVICE_CONFIGURATION,
                RouterHistoryService,
            ], multi: true
        },
        ColorSchemeService,
        { provide: ASSET_SERVICE_CONFIGURATION, useValue: new AssetServiceConfiguration() },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: APP_BASE_HREF, useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(), deps: [PlatformLocation] },
        { provide: 'SocialAuthServiceConfig', useFactory: socialLoginConfigFactory, deps: [ApplicationSettingsService] },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true, closeOnNavigation: true } },
        LanguageService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

    userContext: UserContext;
    private appSettingsService: AppSettingsService;
    private colorSchemeService: ColorSchemeService;

    constructor(
        private authService: AuthService,
        injector: Injector) {

        setAppInjector(injector);
        const locale = injector.get(LOCALE_ID);

        moment.locale(locale);

        const errorHandler = <SentryErrorHandler>injector.get(ErrorHandler);

        this.authService.currentUserContextChanged
            .subscribe(userContext => {
                errorHandler.sentryEnabled = environment.settings?.SentryDsn != null && userContext?.enable_sentry === true;
                Sentry.setUser(userContext ? { id: userContext.id } : null);
            });

        // Initialize app settings monitoring
        this.appSettingsService = injector.get(AppSettingsService);
        this.appSettingsService.startMonitoring();

        // ColorSchemeService
        this.colorSchemeService = injector.get(ColorSchemeService);
    }
}