<ng-container *ngIf="userContext">
    <button matRipple class="flex p-0 rounded-full" [mdePopoverTriggerFor]="userPopover"
        mdePopoverTriggerOn="click" mdePopoverArrowWidth="0" matTooltip="My account" i18n-matTooltip>
        <avatar class="text-5xl" [user]="{ id: userContext.id, initials: getInitials(), photo_url: userContext.photo_asset_key|toAssetUrl }"></avatar>
    </button>

    <mde-popover #userPopover="mdePopover" [mdePopoverCloseOnClick]="false" [mdePopoverOverlapTrigger]="false"> 
        <div class="mat-elevation-z4 bg-white dark:bg-qo-dark-800 dark:text-white rounded-md min-w-80 overflow-hidden w-98p sm:w-88">
            <div class="flex border-b border-qo-divider dark:border-gray-700 h-10">
                <button type="button" matRipple class="flex-1 p-2" [ngClass]="selectedColorSchemeMode == ColorSchemeMode.USER_LIGHT? 'bg-qo-primary/30' : 'opacity-60'" (click)="colorSchemeChanged(ColorSchemeMode.USER_LIGHT)" matTooltip="Light" i18n-matTooltip><mat-icon class="material-icons-outlined" aria-hidden="true" role="img">light_mode</mat-icon></button>
                <button type="button" matRipple class="flex-1 p-2" [ngClass]="selectedColorSchemeMode == ColorSchemeMode.USER_DARK ? 'bg-qo-primary/50' : 'opacity-60'" (click)="colorSchemeChanged(ColorSchemeMode.USER_DARK)" matTooltip="Dark" i18n-matTooltip><mat-icon class="material-icons-outlined" aria-hidden="true" role="img">dark_mode</mat-icon></button>
                <button type="button" matRipple class="flex-1 p-2" [ngClass]="selectedColorSchemeMode == ColorSchemeMode.SYSTEM ? 'bg-qo-primary/30 dark:bg-qo-primary/50' : 'opacity-60'" (click)="colorSchemeChanged(ColorSchemeMode.SYSTEM)" matTooltip="System" i18n-matTooltip><mat-icon class="material-icons-outlined" aria-hidden="true" role="img">desktop_windows</mat-icon></button>
            </div>
            <div class="">
                <avatar class="text-8xl block mx-auto mt-4" [user]="{ id: userContext.id, initials: getInitials(), photo_url: (userContext.photo_asset_key|toAssetUrl) }"></avatar>
                <div class="px-3 pt-2 pb-4"> 
                    <p class="text-center text-lg font-medium m-0" cdkFocusRegionstart>{{userContext?.first_name}} {{userContext?.last_name}}</p>
                    <p class="text-center opacity-80 m-0 line-clamp-1">{{userContext?.email}}</p>
                </div>
                <ul class="p-0 py-3 list-none bg-qo-neutral/30 dark:bg-black/30">
                    <li class="">
                        <a class="font-normal flex no-underline transition-all py-2 px-4 hover:bg-black/5 hover:dark:bg-white/10 w-full" matRipple [routerLink]="NavigationService.AccountRoutes.Manage()" 
                        (click)="closePopover()" i18n>Account settings</a>
                    </li>
                    <li class="">
                        <a class="font-normal flex no-underline transition-all py-2 px-4 hover:bg-black/5 hover:dark:bg-white/10 w-full" matRipple [routerLink]="NavigationService.AccountRoutes.SearchEvent()"
                        (click)="closePopover()" i18n>Search events</a>
                    </li>
                    <li *ngIf="userContext.permissions.includes(ApplicationPermission.ORGANIZATION_REGISTER)" class="">
                        <a class="font-normal flex no-underline transition-all py-2 px-4 hover:bg-black/5 hover:dark:bg-white/10 w-full" matRipple [routerLink]="NavigationService.AccountRoutes.CreateEvent()"
                        (click)="closePopover()" i18n>Organize an event</a>
                    </li>
                    <li class="" *ngIf="userContext.permissions.includes(ApplicationPermission.ADMIN_ACCESS)">
                        <a class="font-normal flex no-underline transition-all py-2 px-4 hover:bg-black/5 hover:dark:bg-white/10 w-full" matRipple [routerLink]="NavigationService.AdminRoutes.Home()"
                        (click)="closePopover()" i18n>Administration</a>
                    </li>
                    <li *ngIf="helpCenterUrl" class="">
                        <a class="font-normal flex no-underline transition-all py-2 px-4 hover:bg-black/5 hover:dark:bg-white/10 w-full" matRipple [href]="helpCenterUrl" i18n-href target="_blank"
                        (click)="closePopover()" i18n>Help center</a>
                    </li>
                </ul>
                <p class="mb-0">
                    <a matRipple class="text-white font-normal flex items-center justify-end w-full h-full no-underline bg-black m-0 hover:bg-black/80 hover:dark:bg-transparent transition-all py-2 px-4" [routerLink]="NavigationService.AuthRoutes.SignOut()">
                        <mat-icon class="mr-2 rotate-180" aria-hidden="true">exit_to_app</mat-icon> <span i18n>Sign out</span>
                    </a>
                </p>
            </div>
        </div>
        
    </mde-popover>
</ng-container>