import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiCustomizationService } from '../ui-customization/ui-customization.service';
import { Organization } from '../../models/organization/organization';
import { Event } from '../../models/event/event';
import { NavigationCustomOrigin } from '../../models/common/navigation-origin';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    currentMenuMode: MenuMode = MenuMode.EMPTY;
    currentEvent?: Event;
    currentOrganization?: Organization;
    menuChanged = new BehaviorSubject<{
        menuMode: MenuMode,
        currentEvent?: Event,
        currentOrganization?: Organization
    }>({ menuMode: MenuMode.EMPTY });

    mobileMenuVisibilityChanged = new BehaviorSubject<boolean>(true);

    // Navigation user settings
    private _userSettingsBS = new BehaviorSubject<NavigationUserSettings | undefined>(undefined);
    public get UserSettings(): NavigationUserSettings | undefined {
        return this._userSettingsBS.getValue();
    }
    public updateUserSettings(changes: Partial<NavigationUserSettings>) {
        let userSettings: NavigationUserSettings = this.UserSettings || {};
        userSettings = Object.assign(userSettings, changes);
        this._userSettingsBS.next(userSettings);
        this.uiCustomizationService.set("NAVIGATION", userSettings);
    }

    // Mobile menu visible
    private _showMobileMenuBS = new BehaviorSubject<boolean>(true);
    public get showMobileMenu(): boolean {
        return this._showMobileMenuBS.getValue();
    }
    public set showMobileMenu(value: boolean) {
        this._showMobileMenuBS.next(value);
    }
    showMobileMenuChanged = this._showMobileMenuBS.asObservable();

    // Layout visible
    private _showLayoutBS = new BehaviorSubject<boolean>(true);
    public get showLayout(): boolean {
        return this._showLayoutBS.getValue();
    }
    public set showLayout(value: boolean) {
        this._showLayoutBS.next(value);
    }
    showLayoutChanged = this._showLayoutBS.asObservable();

    constructor(private uiCustomizationService: UiCustomizationService) {
        this._userSettingsBS.next(this.uiCustomizationService.get<NavigationUserSettings>("NAVIGATION"));
    }

    setMenu(mode: MenuMode.EMPTY | MenuMode.ADMIN): void
    setMenu(mode: MenuMode.ORGANIZATION, context: Organization): void
    setMenu(mode: MenuMode.EVENT, context: Event): void
    setMenu(mode: MenuMode, context?: Organization | Event) {
        this.currentEvent = undefined;
        this.currentOrganization = undefined;
        this.currentMenuMode = mode;
        switch (mode) {
            case MenuMode.EVENT:
                this.currentEvent = context as Event;
                break;
            case MenuMode.ORGANIZATION:
                this.currentOrganization = context as Organization;
                break;
        }
        this.menuChanged.next({
            menuMode: this.currentMenuMode,
            currentEvent: this.currentEvent,
            currentOrganization: this.currentOrganization
        });
    }

    public static HomeRoute(): any[] {
        return ["/"];
    }

    public static EventRoutes = {

        Home(eventId: string): any[] {
            return ['/events', eventId];
        },

        Apply(eventId: string): any[] {
            return ['/apply', eventId];
        },

        EventUnavailable(eventId: string): any[] {
            return ['/events', eventId, 'event-unavailable']
        },

        Admin: {
            Home(eventId: string): any[] {
                return ['/events', eventId, 'admin'];
            },

            Dashboard(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'dashboard'];
            },

            Teammates(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'teammates'];
                return command;
            },

            TeammateList(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'teammates', 'list'];
                return command;
            },

            TeammatePlanning(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'teammates', 'planning'];
                return command;
            },

            TeammateDetails(eventId: string, teammateId: string): any[] {
                return ['/events', eventId, 'admin', 'teammates', teammateId];
            },
            
            TeammateMessaging(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'messaging'];
                return command;
            },

            Activities(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'activities'];
                return command;
            },

            ActivityList(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'activities', 'list'];
                return command;
            },

            ActivityPlanning(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'activities', 'planning'];
                return command;
            },
            
            ActivityShiftList(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'activities', 'shift-list'];
                return command;
            },

            ActivityDetails(eventId: string, activityId: string): any[] {
                return ['/events', eventId, 'admin', 'activities', activityId];
            },

            MaterialItems(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'material-items'];
            },

            MaterialItemDetails(eventId: string, itemId: string): any[] {
                return ['/events', eventId, 'admin', 'material-items', itemId];
            },

            Meals(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'meals'];
            },

            MealDetails(eventId: string, mealId: string): any[] {
                return ['/events', eventId, 'admin', 'meals', mealId];
            },

            MapEditor(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'map'];
            },

            Forms(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'forms'];
            },

            FormDetails(eventId: string, formId: string): any[] {
                return ['/events', eventId, 'admin', 'forms', formId];
            },

            FormPreview(eventId: string, formId: string): any[] {
                return ['/events', eventId, 'admin', 'forms', 'preview', formId];
            },

            ApplicationDetails(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'application'];
            },

            ApplicationPreview(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'application', 'preview'];
            },

            Settings(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings'];
            },

            EventSettings(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'event'];
            },
            
            Attachments(eventId: string) : any[] {
                return ['/events', eventId, 'admin', 'settings', 'attachments'];
            },

            PublicationSettings(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'publication'];
            },           

            Membership(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'users'];
            },

            Integration(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'integrations'];
            },

            Localization(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'localization'];
            },
            
            Badges(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'badges'];
            },

            QrCodeScan(eventId: string) : any[] {
                return ['/events', eventId, 'admin', 'scan'];
            },

            TeammateFields(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'teammate-fields'];
            },

            TeammateArea(eventId: string): any[] {
                return ['/events', eventId, 'admin', 'settings', 'teammate-space'];
            },
            
            TeammateRequirementList(eventId: string): any[] {
                let command = ['/events', eventId, 'admin', 'activities', 'teammate-requirement'];
                return command;
            }
        },

        Teammate: {
            Home(eventId: string): any[] {
                return ['/events', eventId, 'teammate', 'home'];
            },

            Activities(eventId: string): any[] {
                return ['/events', eventId, 'teammate', 'assignments'];
            },

            AssignmentDetails(eventId: string, assignmentId: string): any[] {
                return ['/events', eventId, 'teammate', 'assignments', assignmentId];
            },

            Map(eventId: string): any[] {
                return ['/events', eventId, 'teammate', 'map'];
            },
            
            Attachments(eventId: string): any[] {
                return ['/events', eventId, 'teammate', 'documents'];
            },

            Profile(eventId: string): any[] {
                return ['/events', eventId, 'teammate', 'profile'];
            },

            Services(eventId: string): any[] {
                return ['/events', eventId, 'teammate', 'services'];
            },

            FormDetails(eventId: string, formId: string): any[] {
                return ['/events', eventId, 'teammate', 'forms', formId];
            }
        }
    }

    public static OrganizationRoutes = {
        Home(organizationId: string): any[] {
            return ['/organizations', organizationId, 'dashboard'];
        },

        OrganizationTeammates(organizationId: string): any[] {
            return ['/organizations', organizationId, 'community'];
        },

        OrganizationTeammateList(organizationId: string): any[] {
            return ['/organizations', organizationId, 'community', 'list'];
        },

        OrganizationTeammatePlanning(organizationId: string): any[] {
            return ['/organizations', organizationId, 'community', 'planning'];
        },

        Stats(organizationId: string): any[] {
            return ['/organizations', organizationId, 'stats'];
        },
        
        TeammateMessaging(organizationId: string): any[] {
            let command = ['/organizations', organizationId, 'messaging'];
            return command;
        },

        OrganizationTeammate(organizationId: string, organizationTeammateId: string): any[] {
            return ['/organizations', organizationId, 'community', 'members', organizationTeammateId];
        },

        Events(organizationId: string): any[] {
            return ['/organizations', organizationId, 'events'];
        },

        EventList(organizationId: string): any[] {
            return ['/organizations', organizationId, 'events', 'list'];
        },

        EventPlanning(organizationId: string): any[] {
            return ['/organizations', organizationId, 'events', 'planning'];
        },

        Settings(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings'];
        },

        OrganizationSettings(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings', 'organization'];
        },

        UserList(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings', 'users'];
        },

        SharedFieldsLibrary(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings', 'shared-fields-library'];
        },

        Subscription(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings', 'subscription'];
        },

        Affiliation(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings', 'affiliation'];
        }
    }

    public static AdminRoutes = {
        Home(): any[] {
            return ['/admin'];
        },

        EventList(): any[] {
            return ['/admin/events'];
        },

        OrganizationList(): any[] {
            return ['/admin/organizations'];
        },

        UserList(): any[] {
            return ['/admin/users'];
        },

        Dashboard(): any[] {
            return ['/admin/dashboard'];
        },

        Localizations(): any[] {
            return ['/admin/localization'];
        },

        Settings(): any[] {
            return ['/admin/settings'];
        },
        CustomizeApplication(): any[] {
            return  ['/admin/settings/customize-app'];;
        },
        InformationMessage(): any[] {
            return  ['/admin/settings/information-message'];;
        },

        ThemeCustomization(): any[] {
            return ['/admin/settings/theme-customization'];
        },
        
        OrganizationSettings(organizationId: string): any[] {
            return ['/organizations', organizationId, 'settings', 'organization'];
        },

    }

    public static AccountRoutes = {

        Profile(): any[] {
            return ['/account', 'profile'];
        },

        Manage(): any[] {
            return ['/account', 'manage'];
        },

        Enable2FA(): any[] {
            return ['/account', 'enable-2fa'];
        }, 

        TwoFactorAuthenticationRequired(): any[] {
            return ['/account', 'require-2fa'];
        }, 

        CreateEvent(): any[] {
            return ['/account', 'create-event'];
        },
        
        SearchEvent(): any[] {
            return ['/account', 'search-event'];
        },
        
        Messages(): any[] {
            return ['/account', 'messages'];
        },

        QrCode(): any[] {
            return ['/account', 'qr-code'];
        },

        Delete(): any[] {
            return ['/account', 'delete-profile'];
        }
    }

    public static MiscRoutes = {
        AccessDenied(): any[] {
            return ['/access-denied'];
        },
        
        TrialExpired(): any[] {
            return ['/trial-expired'];
        },
        
        SubscriptionExpired(): any[] {
            return ['/subscription-expired'];
        },
        
        AccountDeleted(): any[] {
            return ['/account-deleted'];
        },
        
        NotFound(): any[] {
            return ['/not-found'];
        },

        Maintenance(): any[] {
            return ['/maintenance'];
        }
    }

    public static AuthRoutes = {
        SignIn(origin?: NavigationCustomOrigin): any[] {
            if(origin === NavigationCustomOrigin.IB) {
                return ["/auth/ib", "signin"];
            }
            return ["/auth", "signin"];
        },

        PasswordSignIn(origin?: NavigationCustomOrigin): any[] {
            if(origin === NavigationCustomOrigin.IB) {
                return ["/auth/ib", "password-signin"];
            }
            return ["/auth", "password-signin"];
        },

        SocialSignIn(origin?: NavigationCustomOrigin): any[] {
            if(origin === NavigationCustomOrigin.IB) {
                return ["/auth/ib", "social-signin"];
            }
            return ["/auth", "social-signin"];
        },

        EmailSignUp(origin?: NavigationCustomOrigin): any[] {
            if(origin === NavigationCustomOrigin.IB) {
                return ["/auth/ib", "email-signup"];
            }
            return ["/auth", "email-signup"];
        },

        SocialSignUp(origin?: NavigationCustomOrigin): any[] {
            if(origin === NavigationCustomOrigin.IB) {
                return ["/auth/ib", "social-signup"];
            }
            return ["/auth", "social-signup"];
        },

        ResetPassword(origin?: NavigationCustomOrigin): any[] {
            if(origin === NavigationCustomOrigin.IB) {
                return ["/auth/ib", "reset-password"];
            }
            return ["/auth", "reset-password"];
        },

        SignOut(): any[] {
            return ["/auth", "signout"];
        },
    }

    public static PwaRoutes = {
        Update(): any[] {
            return ['/pwa/update'];
        }
    }
    
}

export enum MenuMode {
    EMPTY,
    ADMIN,
    EVENT,
    ORGANIZATION
}

export interface NavigationUserSettings {
    homeRedirectionContext?: {
        eventId?: string;
        organizationId?: string;
    };
    navigationOpened?: boolean;
    defaultTeammateView?: "LIST" | "PLANNING";
    defaultActivityView?: "LIST" | "PLANNING" | "SHIFT_LIST";
    defaultOrganizationEventView?: "LIST" | "PLANNING";
    defaultOrganizationTeammateView?: "LIST" | "PLANNING";
}
