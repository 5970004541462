import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './more-menu-dialog.component.html',
    styleUrls: ['./more-menu-dialog.component.scss']
})
export class MoreMenuDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: MoreMenuDialogData) { }
}

export interface MoreMenuDialogData {
    groups: {
        name?: string,
        links: MoreMenuDialogLink[]
    }[],
    bottomLink?: MoreMenuDialogLink;
}

export interface MoreMenuDialogLink {
    icon: string,
    url: any[] | string,
    isExternalUrl: boolean,
    title: string
}