import { Component, Input, Optional, Self, ElementRef, ViewChild, OnDestroy, EventEmitter, Output } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Subject } from 'rxjs';
import { Attachment } from "../../models/common/attachment";
import { IsLoadingService } from "../../services/is-loading/is-loading.service";
import { AssetFileService } from "../../services/asset-file-service/asset-file.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageSubmittedEvent } from "../messaging/messaging.component";
import { SmsCounterService } from "../../services/sms-counter-service/sms-counter.service";
import { OrganizationPermission } from "projects/api-client/src/models/common/OrganizationPermission";
import { OrganizationAdmin } from "../../models/organization/organization-admin";
import { EventContextService } from "src/app/pages/event/event-context.service";
import { MatDialog } from "@angular/material/dialog";
import { FocusMonitor } from "@angular/cdk/a11y";
import { MessageDialogComponent, IconTypes, MessageTypes, MessageDialogData } from "../message-dialog/message-dialog.component";
import { CONSTANTS } from "src/environments/constants";
import { LocalizedKeyString } from "projects/api-client/src/models/common/LocalizedKeyString";
import { StaffEventMessageType } from "projects/api-client/src/models/event-message/commands/Staff/StaffEventMessageType";
import { EventForm } from "../../models/event-form/event-form";
import { Event } from 'src/app/shared/models/event/event';
import { EventPermission } from "projects/api-client/src/models/common/EventPermission";
import { OrderSmsDialogComponent } from "../order-sms-dialog/order-sms-dialog.component";

@Component({
    selector: "message-input",
    templateUrl: "./message-input.component.html",
    styleUrls: ["./message-input.component.scss"]
})
export class MessageInputComponent implements ControlValueAccessor, OnDestroy {
    event: Event;

    OrganizationPermission = OrganizationPermission;
    EventPermission = EventPermission;
    CONSTANTS = CONSTANTS;
    message: string = "";
    attachments: Attachment[] = [];
    sendSms: boolean = false;
    smsCount: number = 1;
    canSendSms: boolean;
    eligibleTeammates: Recipient[];
    nbRecipientsReachableBySms: number = 0;
    attached_forms: EventForm[] = [];

    emojiPanelVisible = false;
    currentView: View = View.MESSAGE;
    View = View;

    @ViewChild("messageContent") messageContentRef: ElementRef;

    @Input() bulkMessage?: boolean;
    @Input() hideSendButton?: boolean;
    @Input() maxAttachments?: number;
    @Input() maxSize: number;
    @Input() canSendPrivateNotes: boolean = false;


    @Input()
    get recipients(): Recipient[] {
        return this._recipients;
    }
    set recipients(value) {
        this._recipients = value;
        if (this._recipients && this._recipients.length > 0) {
            this.nbRecipientsReachableBySms = this._recipients.filter(x => x ? x.is_reachable_by_sms : false).length;
            this.canSendSms = this.nbRecipientsReachableBySms > 0;
        }
        this.stateChanges.next();
    }
    private _recipients: Recipient[] = [];

    @Input() organization?: OrganizationAdmin;
    @Input() enableSms?: boolean;
    @Input() minRows?: number;

    @Input() smsSettings?: SmsSettings;

    @Input() get value(): MessageInputContent {
        return {
            message: this.message,
            attachments: this.attachments,
            sendSms: this.sendSms,
            forms: this.attached_forms
        }
    }
    set value(value: MessageInputContent) {
        if (value) {
            this.message = value.message || "";
            this.attachments = value.attachments ?? [];
            this.sendSms = value.sendSms;
            this.attached_forms = value.forms;
            let contentWithHeader: string = value.message;
            if (this.smsSettings?.sms_header_override || this.smsSettings?.name) {
                contentWithHeader = (this.smsSettings?.sms_header_override || this.smsSettings?.name.text) + "\n" + value.message;
            }
            this.smsCount = SmsCounterService.getSmsCount(contentWithHeader);
            this.stateChanges.next();
        }
    }

    @Input() canSendForms: boolean = false;
    @Input() forms?: EventForm[];
    

    @Output("focus") focus = new EventEmitter<boolean>();

    constructor(
        private isLoadingService: IsLoadingService,
        public assetFileService: AssetFileService,
        private eventContextService: EventContextService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        @Optional() @Self() public ngControl: NgControl,
        private elRef: ElementRef<HTMLElement>,
        private fm: FocusMonitor) {
        this.event = eventContextService.event!;
        if (this.ngControl != null) { this.ngControl.valueAccessor = this; }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.focus.emit(this.focused);
            this.stateChanges.next();
        });
    }

    stateChanges = new Subject<void>();

    @Output() sendingMessage = new EventEmitter<MessageSubmittedEvent>();
    @Output() mobileMenuVisibilityChanged = new EventEmitter<boolean>();

    focused: boolean = false;

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;

    isPendingMessage: boolean;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    writeValue(value: MessageInputContent): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    messageChanged(message: MessageInputContent) {
        this.value = message;
        this.onChange(this.value)
    }

    addEmoji(emojiEvent: EmojiEvent) {
        const input = this.messageContentRef.nativeElement as HTMLTextAreaElement;
        this.message = input.value.substring(0, input.selectionStart) + emojiEvent.emoji.native + input.value.substring(input.selectionEnd);
        input.setSelectionRange(input.selectionStart + 1, input.selectionStart + 1);
        this.onChange(this.value);
    }

    formIsAttached(form: EventForm):boolean{
        return this.attached_forms.some(af => af.id === form.id);
    }

    attachForm(form: EventForm) {
        if(!this.attached_forms.some(af => af.id === form.id)) {
            this.attached_forms.push(form);
            this.onChange(this.value);
        }
    }

    detachForm(form: EventForm, index: number) {
        if(this.attached_forms.some(af => af.id === form.id)) {
            this.attached_forms.splice(index, 1);
            this.onChange(this.value);
        }
    }

    sendMessage(isPrivate: boolean) {
        this.sendingMessage.emit({
            content: this.message,
            type: isPrivate ? StaffEventMessageType.PRIVATE_NOTE : undefined,
            attachment_asset_keys: isPrivate ? [] : this.attachments.map(x => x.asset_key!),
            send_sms: isPrivate ? false : this.sendSms,
            attachment_form_ids: this.attached_forms.map(af => af.id)
        });
    }

    clear() {
        this.message = "";
        this.isPendingMessage = false;      
        this.attachments = [];
        this.attached_forms = [];
        this.onChange(this.value);
    }

    async inputFileChange(file: File) {
        try {
            if (file.size > (this.maxSize * 1024 * 1024)) {
                this.dialog.open(MessageDialogComponent, {
                    data: {
                        title: $localize`The selected file is too big`,
                        body: $localize`Max file size: ${this.maxSize} MB.`,
                        icon: IconTypes.INFO,
                        type: MessageTypes.INFO
                    } as MessageDialogData
                });
            }
            else {
                this.isLoadingService.add({ key: "uploading" });
                const attachmentKey = await this.assetFileService.upload(file, file.name);
                this.attachments.push(new Attachment(undefined, attachmentKey, undefined));
                this.onChange(this.value);
            }
        } catch (exception) {
            this.snackBar.open($localize`Upload failed`);
        } finally {
            this.isLoadingService.remove({ key: "uploading" });
        }
    }

    removeAttachment(attachment: Attachment) {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
        this.onChange(this.value);
    }

    async openAddSmsDialog() {
        if (this.organization) {
            this.dialog.open(OrderSmsDialogComponent, {width: '500px'});
        }
    }

    private $destroy = new Subject();
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.$destroy.next();
        this.$destroy.complete();
    }
}

export interface MessageInputContent {
    message: string;
    attachments: Attachment[];
    sendSms: boolean;
    forms: EventForm[];
}

export interface SmsSettings {
    name: LocalizedKeyString;
    sms_header_override?: string;
    organization_sms_quota: number;
}

export interface Recipient {
    id: string;
    full_name: string;
    user_id?: string;
    is_reachable_by_sms: boolean;
    photo_asset_key?: string;
    is_conversation_resolved: boolean;
}

enum View {
    MESSAGE = "message",
    PRIVATE_NOTE = "private_note"
}
