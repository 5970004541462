<div class="flex flex-col h-full">
    <div class="overflow-auto px-6 py-4">
        <ng-container *ngFor="let group of data.groups">
            <p *ngIf="group.name" class="text-sm font-medium m-0 opacity-60 py-2 px-6">{{group.name}}</p>
            <ul class="qo-Menu list-none my-2 mx-0 p-0 rounded-4xl overflow-hidden border border-gray-200 dark:border-gray-700">
                <li class="border-b border-gray-200 dark:border-gray-700 last:border-0" *ngFor="let link of group.links">
                    <a class="flex items-center p-4" *ngIf="!link.isExternalUrl" [routerLink]="link.url" matRipple>
                        <mat-icon aria-hidden="true" role="img" class="material-icons-outlined opacity-40">{{link.icon}}</mat-icon>
                        <span class="mr-auto ml-3">{{link.title}}</span>
                        <mat-icon aria-hidden="true" role="img" class="material-icons-outlined opacity-40">navigate_next</mat-icon>
                    </a>
                    <a class="flex items-center p-4" *ngIf="link.isExternalUrl" [href]="link.url" target="_blank" matRipple>
                        <mat-icon aria-hidden="true" role="img" class="material-icons-outlined opacity-40">{{link.icon}}</mat-icon>
                        <span class="mr-auto ml-3">{{link.title}}</span>
                        <mat-icon aria-hidden="true" role="img" class="material-icons-outlined opacity-40">open_in_new</mat-icon>
                    </a>
                </li>
            </ul>
        </ng-container>
    </div>
    <div class="flex justify-center mt-auto py-4">
        <a *ngIf="data.bottomLink" [routerLink]="data.bottomLink.url" mat-flat-button color="accent">
            <mat-icon aria-hidden="true" role="img" class="material-icons-outlined">{{data.bottomLink.icon}}</mat-icon>
            <span>{{data.bottomLink.title}}</span>
        </a>
    </div>
</div>