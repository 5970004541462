import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class TrialService {
    private trialEndDate: Date | undefined = undefined;
    public get displayTrialBanner(): Readonly<boolean> { return !!this.trialEndDate }

    public setTrialEndDate(b: Date | undefined) {
        this.trialEndDate = b;
    }

    public getTrialTimeLeft() {
        if(moment(this.trialEndDate).isSame(moment(), 'day')) {
            return 'today';
        } else if(moment(this.trialEndDate).isSame(moment().add(1, 'day'), 'day')) {
            return 'tomorrow';
        } else if(moment(this.trialEndDate).isAfter(moment())){
            return 'later';
        } else {
            return 'expired';
        }
    }

    public getTrialTimeLeftInDays() {
        return moment(this.trialEndDate).diff(moment(), 'days');
    }
    
}