import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessagingAPIService } from 'projects/api-client/src/public-api';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdleMonitoringService } from '../../services/idle-monitoring-service/idle-monitoring.service';
import { NavigationService } from '../../services/navigation-service/navigation.service';

@Component({
    selector: 'messaging-panel',
    templateUrl: './messaging-panel.component.html',
    styleUrls: ['./messaging-panel.component.scss']
})
export class MessagingPanelComponent implements OnInit, OnDestroy {

    NavigationService = NavigationService;
    notRead: number = 0;
    isUserIdle: boolean = false;

    constructor(private messagingAPIService: MessagingAPIService,
        idleMonitoringService: IdleMonitoringService) {

        idleMonitoringService.idleStatusChanged.pipe(takeUntil(this.$destroy))
            .subscribe(async isUserIdle => {
                this.isUserIdle = isUserIdle;
            });

        interval(60000) // every mins
            .pipe(takeUntil(this.$destroy))
            .subscribe(async () => {
                try {
                    if (!this.isUserIdle && document.visibilityState === 'visible') {
                        this.notRead = await this.getUnreadMessagesCount();
                    }
                } catch {
                    // silent exception
                }
            });
    }

    async ngOnInit(): Promise<void> {
        this.notRead = await this.getUnreadMessagesCount();
    }

    async getUnreadMessagesCount(): Promise<number> {
        const notifications = await this.messagingAPIService.getNotifications().toPromise();
        return notifications.unread_messages_count;
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}