import { Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationRole } from 'projects/api-client/src/models/common/ApplicationRole';
import { AuthService, UserContext } from 'projects/api-client/src/public-api';
import { NavigationService } from '../../services/navigation-service/navigation.service';
import { ApplicationPermission } from 'projects/api-client/src/models/common/ApplicationPermission';
import { ColorSchemeMode, ColorSchemeService } from '../../services/color-scheme/color-scheme.service';
import { MdePopoverTrigger } from '@material-extended/mde';
import { ApplicationSettingsService } from '../../services/application-settings-service/application-settings.service';
import { ClientSettingsService } from '../../services/client-settings-service/client-settings.service';

@Component({
    selector: 'my-account-menu',
    templateUrl: './my-account-menu.component.html'
})
export class MyAccountMenuComponent {
    
    @ViewChild(MdePopoverTrigger, { static: false }) trigger: MdePopoverTrigger;

    ColorSchemeMode = ColorSchemeMode;
    ApplicationRole = ApplicationRole;
    ApplicationPermission = ApplicationPermission;

    userContext: UserContext | null;
    NavigationService = NavigationService;

    selectedColorSchemeMode: string = ColorSchemeMode.USER_LIGHT;
    
    helpCenterUrl?: string;

    private _unsubscribeAll = new Subject<any>();

    constructor(private authService: AuthService,
        applicationSettingsService: ApplicationSettingsService,
        clientSettingsService: ClientSettingsService,
        private colorSchemeService: ColorSchemeService) {
        this.authService.currentUserContextChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(userContext => {
                if (userContext) {
                    this.userContext = userContext;
                }
            });

        this.helpCenterUrl = clientSettingsService.getValueInLocale(clientSettingsService.clientSettings?.help_center_url);
        this.selectedColorSchemeMode = this.colorSchemeService.colorSchemeMode ?? ColorSchemeMode.USER_LIGHT;
    }

    closePopover() {
        this.trigger.togglePopover();
    }

    getInitials(): string {
        return this.userContext ? `${this.userContext?.first_name?.charAt(0)}${this.userContext?.last_name?.charAt(0)}`.toUpperCase() : "";
    }

    colorSchemeChanged(colorScheme: ColorSchemeMode) {
        this.colorSchemeService.updateColorScheme(colorScheme);
        this.selectedColorSchemeMode = colorScheme;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
