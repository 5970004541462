import { LocalizedKeyString } from "projects/api-client/src/models/common/LocalizedKeyString";
import { TeammateFieldDefinition } from "../event-teammate-field-definition/teammate-field-definition";
import { EventTeammateFieldSectionResponseDTO } from 'projects/api-client/src/models/event-teammate-field-section/responses/EventTeammateFieldSectionResponseDTO';
import { EventTeammateFieldSectionUpdateCommandDTO } from "projects/api-client/src/models/event-teammate-field-section/commands/EventTeammateFieldSectionUpdateCommandDTO";

export class EventTeammateFieldSection {
    id!: string;
    name!: LocalizedKeyString;
    position!: number;
    fields: TeammateFieldDefinition[] = []; 
    created_at?: Date;
    updated_at?: Date;

    static from(dto: EventTeammateFieldSectionResponseDTO): EventTeammateFieldSection {
        const teammateFieldSection = new EventTeammateFieldSection();        
        teammateFieldSection.id = dto.id;
        teammateFieldSection.name = dto.name;
        teammateFieldSection.position = dto.position;
        teammateFieldSection.fields = dto.fields.map(f => TeammateFieldDefinition.from(f));
        teammateFieldSection.created_at = dto.created_at;
        teammateFieldSection.updated_at = dto.updated_at;
        return teammateFieldSection;
    }

    asUpdateCommand(): EventTeammateFieldSectionUpdateCommandDTO {
        return {
            name: this.name.key,
            position: this.position,
            updated_at: this.updated_at
        }
    }
}