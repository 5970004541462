import { NotificationResponseDTO } from 'projects/api-client/src/models/notification/responses/NotificationResponseDTO';
import { InAppNotificationType } from 'projects/api-client/src/models/common/NotificationType';
import { LocalizedKeyString } from 'projects/api-client/src/models/common/LocalizedKeyString';

export class Notification {

    id: string;
    type: InAppNotificationType;
    event_id?: string;
    timestamp: Date;
    read: boolean;
    thumbnail_asset_key?: string;
    thumbnail_title?: LocalizedKeyString;

    constructor(notificationDTO?: NotificationResponseDTO) {
        if (notificationDTO) {
            this.id = notificationDTO.id;
            this.type = notificationDTO.type;
            this.event_id = notificationDTO.event_id;
            this.timestamp = new Date(notificationDTO.timestamp);
            this.read = notificationDTO.read;
            this.thumbnail_asset_key = notificationDTO.thumbnail_asset_key;
            this.thumbnail_title = notificationDTO.thumbnail_title;
        }
    }
}