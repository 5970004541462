import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AccessDeniedComponent } from './pages/misc/access-denied/access-denied.component';
import { MaintenanceComponent } from './pages/misc/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/misc/not-found/not-found.component';
import { NavigationCustomOrigin } from './shared/models/common/navigation-origin';
import { ProfileDeletedComponent } from './pages/misc/profile-deleted/profile-deleted.component';
import { HomeRedirectComponent } from './home-redirect.component';
import { ApplicationPermission } from 'projects/api-client/src/models/common/ApplicationPermission';
import { TrialExpiredComponent } from './pages/misc/trial-expired/trial-expired.component';
import { SubscriptionExpiredComponent } from './pages/misc/subscription-expired/subscription-expired.component';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
    },    
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard]
    },    
    { 
        path: "organizations/:id", 
        loadChildren: () => import("./pages/organization/organization.module").then(x => x.OrganizationModule),
        canActivate: [AuthGuard]
    },
    {
        path: "affiliate",
        loadChildren: () => import("./pages/affiliate/affiliate.module").then(x => x.AffiliateModule),
        canActivate: [AuthGuard]
    },
    { 
        path: "apply/ib/:id",
        loadChildren: () => import('./pages/apply/apply.module').then(m => m.ApplyModule),
        data: { origin: NavigationCustomOrigin.IB },
        canActivate: [AuthGuard]
    },
    { 
        path: "apply/:id",
        loadChildren: () => import('./pages/apply/apply.module').then(m => m.ApplyModule),
        canActivate: [AuthGuard]
    },    
    {
        path: 'events/:id',
        loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        data: { 
            authorization: {
                requiredApplicationPermissions: [ApplicationPermission.ADMIN_ACCESS]
            }
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'pwa',
        loadChildren: () => import('./pages/pwa/pwa.module').then(m => m.PwaModule)
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent
    },
    {
        path: 'trial-expired',
        component: MainLayoutComponent,
            children: [
                {
                    path: "",
                    component: TrialExpiredComponent
                }
            ]
    },
    {
        path: 'subscription-expired',
        component: MainLayoutComponent,
            children: [
                {
                    path: "",
                    component: SubscriptionExpiredComponent
                }
            ]
    },
    {
        path: 'account-deleted',
        component: ProfileDeletedComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: '**',
        component: HomeRedirectComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    anchorScrolling: "enabled",
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 64]
})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
