import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { PlatformLanguageCode } from 'projects/api-client/src/models/common/PlatformLanguageCode';
import { ApplicationSettingsAPIService } from 'projects/api-client/src/public-api';
import { Subject, Subscription, interval } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClientSettingsService {

    private monitorSubscription!: Subscription;

    private defaultClientSettings: any = {
        onboarding_video_enabled: false,
        supported_languages: [PlatformLanguageCode.EnUS]
    }

    clientSettings?: ClientSettings = this.defaultClientSettings;
    clientSettingsChange: Subject<any> = new Subject<any>();

    constructor(@Inject(LOCALE_ID) private locale: string, private applicationSettingsAPIService: ApplicationSettingsAPIService) { }

    public async startMonitoring(frequency = 60) {
        const loop = async () => {
            try {
                this.refresh();
            }
            catch { }
        }
        loop();
        this.monitorSubscription = interval(frequency * 1000)
            .subscribe(async () => {
                loop();
            });
    }

    public stopMonitoring() {
        if (this.monitorSubscription && !this.monitorSubscription.closed)
            this.monitorSubscription.unsubscribe();
    }

    async refresh() {
        let settings = await this.applicationSettingsAPIService.getPublicSettings().toPromise();
        this.clientSettings = Object.assign(this.defaultClientSettings, settings.application_identity.client_settings);
        this.clientSettingsChange.next(this.clientSettings);
    }

    getValueInLocale<T>(value?: LocalizedValue<T>): T | undefined {
        if (value) 
        {
            return value[this.locale] || value[PlatformLanguageCode.EnUS];
        }
        return undefined;
    }
}

export interface ClientSettings {
    onboarding_video_enabled: boolean;
    supported_languages: PlatformLanguageCode[];
    help_center_url?: LocalizedValue<string>;
    sales_email?: LocalizedValue<string>;
    privacy_url?: LocalizedValue<string>;
    terms_url?: LocalizedValue<string>;
    enable_advanced_contact_import?: boolean;
    admin?: any;
}

interface LocalizedValue<T> {
    [locale: string]: T;
}
