import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation-service/navigation.service';
import { ClientSettingsService } from '../../services/client-settings-service/client-settings.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

    NavigationService = NavigationService;

    termsUrl?: string;
    privacyUrl?: string;

    constructor(private clientSettingsService: ClientSettingsService) { }

    async ngOnInit() {
        await this.clientSettingsService.refresh().then(_ => {
            this.termsUrl = this.clientSettingsService.getValueInLocale(this.clientSettingsService.clientSettings?.terms_url);
            this.privacyUrl = this.clientSettingsService.getValueInLocale(this.clientSettingsService.clientSettings?.privacy_url);
        })
    }

}
