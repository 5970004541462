import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationAPIService } from 'projects/api-client/src/public-api';
import { Subject, interval } from 'rxjs';
import { DataService } from '../../services/data-service/data.service';
import { Notification } from '../../models/notification/notification';
import { InAppNotificationType } from 'projects/api-client/src/models/common/NotificationType';
import { takeUntil } from 'rxjs/operators';
import { SortService } from '../../services/sort-service/sort.service';
import { NavigationService } from '../../services/navigation-service/navigation.service';
import { IdleMonitoringService } from '../../services/idle-monitoring-service/idle-monitoring.service';

@Component({
    selector: 'notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit, OnDestroy {

    UserNotificationType = InAppNotificationType;
    dataService: DataService<Notification>;
    NavigationService = NavigationService;
    
    notRead: number = 0;
    isUserIdle: boolean = false;
    
    supportedTypes: InAppNotificationType[] = [InAppNotificationType.EVENT_APPLICATION_PENDING,
        InAppNotificationType.EVENT_APPLICATION_WAITING_LIST,
        InAppNotificationType.EVENT_APPLICATION_ACCEPTED,
        InAppNotificationType.EVENT_APPLICATION_DECLINED,
        InAppNotificationType.EVENT_APPLICATION_CANCELED,
        InAppNotificationType.EVENT_APPLICATION_DELETED,
        InAppNotificationType.EVENT_PLANNING_UPDATED];

    constructor(notificationAPIService: NotificationAPIService, idleMonitoringService: IdleMonitoringService) {

        this.dataService = new DataService(async () => {
                const previousNotifications = this.dataService.datasource;
                try {                
                    const notifications = await notificationAPIService.list().toPromise();
                    return notifications
                        .filter(x => this.supportedTypes.includes(x.type))
                        .map(x => new Notification(x))
                        .sort(SortService.getDateComparator("timestamp", "DESC"))
                        .slice(0, 50);
                } catch {
                    // silent (and preserve existing notifications)
                    return previousNotifications;
                }
            }, {
                filters: [],
                searchKeys: []
            }
        );

        this.dataService.$datasource
            .pipe(takeUntil(this.$destroy))
            .subscribe(notifications => {
                this.notRead = notifications.filter(x => !x.read).length;
            });

        idleMonitoringService.idleStatusChanged.pipe(takeUntil(this.$destroy))
            .subscribe(async isUserIdle => {
                this.isUserIdle = isUserIdle;
            });

        interval(60000) // every mins
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
                if (!this.isUserIdle && document.visibilityState === 'visible') {
                    this.dataService.refresh();
                }
            });
    }

    ngOnInit(): void {
        this.dataService.refresh();
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}