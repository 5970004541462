import { OrganizationAPIService } from 'projects/api-client/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Organization } from 'src/app/shared/models/organization/organization';
import { OrganizationPermission } from 'projects/api-client/src/models/common/OrganizationPermission';
import { OrganizationAdmin } from 'src/app/shared/models/organization/organization-admin';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrganizationContextService {

    public get organization(): Organization | undefined { return this.organizationContextBS.getValue().organization; }
    public get organizationAdmin(): OrganizationAdmin | undefined { return this.organizationContextBS.getValue().organizationAdmin; }

    protected organizationContextBS = new BehaviorSubject<{
        organization?: Organization,
        organizationAdmin?: OrganizationAdmin
    }>({});
    public onOrganizationContextChanged = this.organizationContextBS.asObservable();

    constructor(private organizationAPIService: OrganizationAPIService) {
        this.onOrganizationContextChanged = this.organizationContextBS.asObservable();
    }
    
    async loadOrganization(organizationId: string) {
        const organization = Organization.from(await this.organizationAPIService.get(organizationId).toPromise());
        let organizationAdmin: OrganizationAdmin|undefined = undefined;
        if(organization.user_organization_permissions.includes(OrganizationPermission.ADMIN_ACCESS)) {
            organizationAdmin = OrganizationAdmin.from(await this.organizationAPIService.getAdmin(organizationId).toPromise());
        }
        this.organizationContextBS.next({ organization, organizationAdmin });
    }

    async refresh() {
        if(this.organization) {
            await this.loadOrganization(this.organization.id);
        }
    }
    
    public get joinUrl(): string | undefined {
        if (this.organization) {
            return `${location.origin}/go/organizations/${this.organization.id}/join-community`;
        } else {
            return undefined;
        }
    }

}
