import { Inject, Injectable, InjectionToken } from '@angular/core';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { AssetAPIService } from 'projects/api-client/src/public-api';

export const ASSET_SERVICE_CONFIGURATION = new InjectionToken<string>("AssetServiceConfiguration");

@Injectable()
export class AssetServiceConfiguration {
    public baseUrl: string = "";
    constructor() { }
}

@Injectable({
    providedIn: 'root'
})
export class AssetFileService {

    constructor(private assetAPIService: AssetAPIService,
        @Inject(ASSET_SERVICE_CONFIGURATION) private configuration: AssetServiceConfiguration) { 
        }

    async upload(file: any, filename: string): Promise<string> {

        const token = await this.assetAPIService.createUploadToken().toPromise();
        const client = new S3Client({
            region: token.bucket_region,
            credentials: {
                accessKeyId: token.access_key_id,
                secretAccessKey: token.secret_access_key,
                sessionToken: token.session_token
            }
        });
        const prefix = token.key_prefix.endsWith("*") ? token.key_prefix.substring(0, token.key_prefix.length - 1) : token.key_prefix;
        const key = `${prefix}${filename}`;

        await client.send(new PutObjectCommand({
            Bucket: token.bucket_name,
            Key: key,
            Body: file,
            ContentType: file.type
        }));

        return key;

    }

    static getExtension(filename: string): string | undefined {
        if (filename) {
            const nameParts = filename.split(".");
            return nameParts[nameParts.length - 1].toLowerCase();
        } else {
            return undefined;
        }
    }
    
    static getFilename(file_url: string): string | undefined {
        if (!file_url) return undefined;
        else {
            const urlSegments = file_url.split("/");
            return urlSegments[urlSegments.length - 1];
        }
    }

    static dataURLtoFile(dataurl: any): File {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let result: any = new Blob([u8arr], { type: mime });
        return result;
    }
    
    public getAssetUrlFromKey(assetKey: string | undefined | null): string | undefined {
        if (!assetKey) return undefined;
        return new URL(assetKey.split("/").map(x => encodeURIComponent(x)).join("/"), this.configuration.baseUrl).toString();
    }

    public static async resizeImage(file: File, maxWidth?: number, maxHeight?: number): Promise<File> {

        if (!maxWidth && !maxHeight) return file;

        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement('canvas');

        return new Promise((resolve, reject) => {
            if (!file.type.match(/image.*/)) {
                reject(new Error("Not an image"));
                return;
            }
            reader.onload = (readerEvent: any) => {
                image.onload = () => {
                    let width = image.width;
                    let height = image.height;
                    if (maxWidth && width > maxWidth) {
                        height = height * (maxWidth / width);
                        width = maxWidth;
                    }
                    if (maxHeight && height > maxHeight) {
                        width = width * (maxHeight / height);
                        height = maxHeight;
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d")!.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL('image/jpeg');
                    resolve(AssetFileService.dataURLtoFile(dataUrl));
                }
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    }
}
