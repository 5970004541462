import { Address } from '../common/address';
import { OrganizationRole } from 'projects/api-client/src/models/common/OrganizationRole';
import { OrganizationPermission } from 'projects/api-client/src/models/common/OrganizationPermission';
import { OrganizationResponseDTO } from 'projects/api-client/src/models/organization/responses/OrganizationResponseDTO';

export class OrganizationSummary {
    id: string;
    name: string;
    contact_phone: string;
    logo_asset_key?: string;
    address?: Address;
    licenses_quota: number;
    sms_quota: number;
    user_organization_role: OrganizationRole;
    user_organization_permissions: OrganizationPermission[];
    created_at: Date;
    updated_at: Date;

    static from(dto: OrganizationResponseDTO) {
        const organization = new OrganizationSummary();
        organization.id = dto.id;
        organization.name = dto.name;
        organization.contact_phone = dto.contact_phone;
        organization.logo_asset_key = dto.logo_asset_key;
        organization.address = dto.address ? new Address(dto.address) : undefined;
        organization.licenses_quota = dto.licenses_quota;
        organization.sms_quota = dto.sms_quota ?? 0;
        organization.user_organization_role = dto.user_organization_role;
        organization.user_organization_permissions = dto.user_organization_permissions;
        organization.created_at = new Date(dto.created_at);
        organization.updated_at = new Date(dto.updated_at);

        return organization;
    }    
}