import { NgModule, ModuleWithProviders } from '@angular/core';
import { AppSettingsService } from './services/app-settings/app-settings.service';
import { MinAgeDirective } from './directives/min-age-validator.directive';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../angular-material.module';
import { NavigationService } from './services/navigation-service/navigation.service';
import { AsUtcPipe } from './pipes/as-utc.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { DateService } from './services/date-service/date.service';
import { IsLoadingPipe } from './services/is-loading/is-loading.pipe';
import { AssetFileService } from 'src/app/shared/services/asset-file-service/asset-file.service';
import { PhotoInputComponent } from './components/photo-input/photo-input.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { IsLoadingService } from './services/is-loading/is-loading.service';
import { UiCustomizationService } from './services/ui-customization/ui-customization.service';
import { TimelineGroupComponent } from './components/timeline/timeline-group.component';
import { TimelineHeaderComponent } from './components/timeline/timeline-header.component';
import { TimelineItemComponent, TimelineRowComponent } from './components/timeline/timeline-row.component';
import { LanguageSkillsInputComponent } from './components/language-skills-input/language-skills-input.component';
import { UnavailabilitiesInputComponent } from './components/unavailabilities-input/unavailabilities-input.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { VocabularyService } from './services/vocabulary-service/vocabulary.service';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MinSelectedChoicesDirective } from './directives/min-choices-selected-validator.directive';
import { AgePipe } from './pipes/age.pipe';
import { BulkActionsComponent } from './components/bulk-actions/bulk-actions.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { DateTimeInput } from './components/date-time-input/date-time-input.component';
import { GoBackDirective } from './directives/go-back';
import { SliderComponent, SliderItemDirective } from './components/slider/slider.component';
import { DurationPipe } from './pipes/duration';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { MessagingComponent } from './components/messaging/messaging.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FromNowPipe } from './pipes/from-now.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { RichTextInputComponent } from './components/richtext-input/richtext-input.component';
import { JoinSlicePipe } from './pipes/join-slice';
import { FromToPipe } from './pipes/from-to.pipe';
import { AttachmentsInputComponent } from './components/attachments-input/attachments-input.component';
import { ShareApplicationFormDialogComponent } from './components/share-application-form-dialog/share-application-form-dialog.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { MyAccountMenuComponent } from './layouts/my-account-menu/my-account-menu.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { ImageDialogDirective } from './components/image-dialog/Image-dialog.directive';
import { TimelineRowGroupComponent } from './components/timeline/timeline-row-group.component';
import { PeriodicityPipe } from './pipes/periodicity.pipe';
import { SendTeammateMessageDialogComponent } from './components/send-teammate-message-dialog/send-teammate-message-dialog.component';
import { AddressPipe } from './pipes/address';
import { SpinnerColorDirective } from './directives/spinner-color.directive';
import { ResponsiveDirective } from './directives/responsive';
import { OrderSmsDialogComponent } from './components/order-sms-dialog/order-sms-dialog.component';
import { CurrencyNoZeroCentsPipe } from './pipes/currency-no-zero-cents.pipe';
import { OrderEventLicensesDialogComponent } from './components/order-event-licenses-dialog/order-event-licenses-dialog.component';
import { MatomoService } from './services/matomo-service/matomo-service';
import { TimeInput } from './components/time-input/time-input.component';
import { MoreMenuDialogComponent } from './layouts/more-menu-dialog/more-menu-dialog.component';
import { PasswordStrengthMeterComponent } from './components/password-strength-meter/password-strength-meter.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapToolboxComponent } from './components/map-toolbox/map-toolbox.component';
import { MapSearchComponent } from './components/map-search/map-search.component';
import { MapLocationInputComponent } from './components/map-location-input/map-location-input.component';
import { PointOfInterestEditDialogComponent } from './components/point-of-interest-edit-dialog/point-of-interest-edit-dialog.component';
import { PointOfInterestCreateDialogComponent } from './components/point-of-interest-create-dialog/point-of-interest-create-dialog.component';
import { TimelineDateInputComponent } from './components/timeline/timeline-date-input/timeline-date-input.component';
import { FixedRangeDirective } from './components/timeline/timeline-date-input/FixedRangeSelectionStrategy';
import { TeammateExperienceDialogComponent } from './components/teammate-experience-dialog/teammate-experience-dialog.component';
import { DocumentInputComponent } from './components/document-input/document-input.component';
import { SaveAsDirective } from './directives/save-as';
import { AvatarComponent } from './components/avatar/avatar.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorTranslationService } from './services/paginator-translation-service/paginator.translation.service';
import { MaxDateDirective } from './directives/max-date-validator.directive';
import { SafePipe } from './pipes/safe.pipe';
import { MessageInputComponent } from './components/message-input/message-input.component';
import { OrderOrganizationLicensesDialogComponent } from './components/order-organization-licenses-dialog/order-organization-licenses-dialog.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { MessagingPanelComponent } from './components/messaging-panel/messaging-panel.component';
import { UserSpaceSelectorComponent } from './layouts/user-space-selector/user-space-selector.component';
import { AddOrganizationDialogComponent } from './components/add-organization-dialog/add-organization-dialog.component';
import { EventListItemComponent } from './components/event-list-item/event-list-item.component';
import { InViewDirective } from './directives/in-view.directive';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { MapAddressViewComponent } from './components/map-address-view/map-address-view.component';
import { GeolocationService } from './services/geolocation-service/geolocation.service';
import { CustomAddressDialogComponent } from './components/custom-address-dialog/custom-address-dialog.component';
import { AutofillDisabledDirective } from './directives/autofill-disabled';
import { SearchHighlightPipe } from './pipes/search-highlight';
import { CalendarMultiSelectComponent } from './components/calendar-multi-select/calendar-multi-select.component';
import { LocalizePipe } from './pipes/localize.pipe';
import { LocalizeArrayPipe } from './pipes/localize-array.pipe';
import { TeammateTimelineComponent } from './components/teammate-timeline/teammate-timeline.component';
import { EncryptDecryptService } from './services/encrypt-decrypt-service/encrypt-decrypt-service';
import { LinkyModule, LinkyPipe } from 'ngx-linky';
import { JoinEventSupportersSelectorComponent } from './components/join-event-supports-selector/join-event-supports-selector.component';
import { TimeRangePipe } from './pipes/time-range';
import { TableSettingsDialogComponent } from './components/table-settings/table-settings-dialog/table-settings-dialog.component';
import { TableSettingsEditorComponent } from './components/table-settings/table-settings-editor/table-settings-editor.component';
import { ToAssetUrlPipe } from './pipes/to-asset-url.pipe';
import { ClientSettingsService } from './services/client-settings-service/client-settings.service';
import { CalendarMonthSlotComponent } from './components/calendar-month-slot/calendar-month-slot.component';
import { StringListEditorIntlService } from './localization/string-list-editor.service';
import { StringListEditorIntl } from '@qo/commons';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { LocalDateFromToPipe } from './pipes/localDate-from-to.pipe';
import { LocalDateTimePipe } from './pipes/localDateTime.pipe';
import { AngularIbanModule } from 'angular-iban';
import { BankAccountInputComponent } from './components/bank-account-input/bank-account-input.component';

@NgModule({
    declarations: [
        ImageCropperDialogComponent,
        PhotoInputComponent,
        UnavailabilitiesInputComponent,
        LanguageSkillsInputComponent,
        PhoneInputComponent,
        MainLayoutComponent,
        AuthLayoutComponent,
        MinAgeDirective,
        MaxDateDirective,
        ResponsiveDirective,
        MinSelectedChoicesDirective,
        GoBackDirective,
        SpinnerColorDirective,
        AsUtcPipe,
        MomentPipe,
        FromNowPipe,
        IsLoadingPipe,
        AddressPipe,
        AgePipe,
        LocalizePipe,
        LocalizeArrayPipe,
        YesNoPipe,
        JoinSlicePipe,
        DurationPipe,
        FromToPipe,
        LocalDateFromToPipe,
        LocalDateTimePipe,
        TimeRangePipe,
        PeriodicityPipe,
        CurrencyNoZeroCentsPipe,
        SafePipe,
        SearchHighlightPipe,
        ToAssetUrlPipe,
        SearchFilterComponent,
        TableSettingsDialogComponent,
        TableSettingsEditorComponent,
        TimelineGroupComponent,
        TimelineRowGroupComponent,
        TimelineHeaderComponent,
        TimelineRowComponent,
        TimelineItemComponent,
        MessageDialogComponent,
        BulkActionsComponent,
        TagsInputComponent,
        RichTextInputComponent,
        MessageInputComponent,
        DateTimeInput,
        TimeInput,
        SliderComponent,
        SliderItemDirective,
        NotificationPanelComponent,
        MessagingPanelComponent,
        MessagingComponent,
        AttachmentsInputComponent,
        ShareApplicationFormDialogComponent,
        FooterComponent,
        MyAccountMenuComponent,
        ImageDialogComponent,
        ImageDialogDirective,
        SendTeammateMessageDialogComponent,
        SpinnerColorDirective,
        OrderSmsDialogComponent,
        OrderEventLicensesDialogComponent,
        OrderOrganizationLicensesDialogComponent,
        MoreMenuDialogComponent,
        PasswordStrengthMeterComponent,
        MapToolboxComponent,
        MapSearchComponent,
        MapLocationInputComponent,
        PointOfInterestCreateDialogComponent,
        PointOfInterestEditDialogComponent,
        TimelineDateInputComponent,
        FixedRangeDirective,
        TeammateExperienceDialogComponent,
        DocumentInputComponent,
        BankAccountInputComponent,
        SaveAsDirective,
        AvatarComponent,
        LanguageSelectorComponent,
        UserSpaceSelectorComponent,
        AddOrganizationDialogComponent,
        EventListItemComponent,
        InViewDirective,
        AddressInputComponent,
        MapAddressViewComponent,
        CustomAddressDialogComponent,
        AutofillDisabledDirective,
        CalendarMultiSelectComponent,
        TeammateTimelineComponent,
        JoinEventSupportersSelectorComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        ImageCropperModule,
        PickerModule,
        GoogleMapsModule,
        LinkyModule,
        CalendarMonthSlotComponent,
        AngularIbanModule
    ],
    exports: [
        ImageCropperDialogComponent,
        PhotoInputComponent,
        UnavailabilitiesInputComponent,
        LanguageSkillsInputComponent,
        PhoneInputComponent,
        MainLayoutComponent,
        AuthLayoutComponent,
        MinAgeDirective,
        MaxDateDirective,
        MinSelectedChoicesDirective,
        GoBackDirective,
        SpinnerColorDirective,
        AsUtcPipe,
        MomentPipe,
        FromNowPipe,
        IsLoadingPipe,
        AddressPipe,
        AgePipe,
        YesNoPipe,
        JoinSlicePipe,
        DurationPipe,
        FromToPipe,
        LocalDateFromToPipe,
        LocalDateTimePipe,
        TimeRangePipe,
        PeriodicityPipe,
        CurrencyNoZeroCentsPipe,
        SafePipe,
        SearchHighlightPipe,
        SearchFilterComponent,
        TableSettingsDialogComponent,
        TableSettingsEditorComponent,
        TimelineGroupComponent,
        TimelineRowGroupComponent,
        TimelineHeaderComponent,
        TimelineRowComponent,
        TimelineItemComponent,
        BulkActionsComponent,
        TagsInputComponent,
        RichTextInputComponent,
        MessageInputComponent,
        DateTimeInput,
        TimeInput,
        SliderComponent,
        SliderItemDirective,
        ResponsiveDirective,
        MessagingComponent,
        ShareApplicationFormDialogComponent,
        AttachmentsInputComponent,
        ImageDialogComponent,
        ImageDialogDirective,
        SendTeammateMessageDialogComponent,
        MessageDialogComponent,
        OrderSmsDialogComponent,
        FooterComponent,
        OrderEventLicensesDialogComponent,
        OrderOrganizationLicensesDialogComponent,
        PasswordStrengthMeterComponent,
        MapToolboxComponent,
        MapSearchComponent,
        MapLocationInputComponent,
        PointOfInterestCreateDialogComponent,
        PointOfInterestEditDialogComponent,
        TimelineDateInputComponent,
        DocumentInputComponent,
        SaveAsDirective,
        AvatarComponent,
        LanguageSelectorComponent,
        UserSpaceSelectorComponent,
        AddOrganizationDialogComponent,
        EventListItemComponent,
        InViewDirective,
        AddressInputComponent,
        BankAccountInputComponent,
        MapAddressViewComponent,
        CustomAddressDialogComponent,
        AutofillDisabledDirective,
        LocalizePipe,
        LocalizeArrayPipe,
        TeammateTimelineComponent,
        LinkyPipe,
        ToAssetUrlPipe,
        JoinEventSupportersSelectorComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                NavigationService,
                AppSettingsService,
                ClientSettingsService,
                DateService,
                AssetFileService,
                IsLoadingService,
                UiCustomizationService,
                VocabularyService,
                GeolocationService,
                EncryptDecryptService,
                MatomoService,
                { provide: MatPaginatorIntl, useClass: PaginatorTranslationService },
                { provide: StringListEditorIntl, useClass: StringListEditorIntlService }
            ]
        };
    }
}
