<button mat-icon-button [mdePopoverTriggerFor]="notificationPopover" mdePopoverTriggerOn="click"
    mdePopoverArrowWidth="0" matTooltip="Notifications" i18n-matTooltip>
    <mat-icon class="material-icons-outlined" *ngIf="notRead > 0" [matBadge]="notRead" matBadgeSize="small" matBadgeColor="warn">notifications_active</mat-icon>
    <mat-icon *ngIf="notRead === 0" class="material-icons-outlined">notifications</mat-icon>
</button>
<mde-popover #notificationPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
    <div class="mat-elevation-z4 qo-scrollbar bg-white dark:bg-qo-dark-800 dark:text-white rounded-md min-w-70 max-h-70vh w-98p sm:w-112">
            <ng-container  *ngFor="let notification of dataService.$datasource|async">
            
            <ng-container [ngSwitch]="notification.type">

                <a *ngSwitchCase="UserNotificationType.EVENT_APPLICATION_ACCEPTED" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                    [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id)" matRipple>
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your application has been accepted</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </a>    

                <a *ngSwitchCase="UserNotificationType.EVENT_APPLICATION_PENDING" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}" 
                [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id)" matRipple>
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your application is pending</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </a>  

                <a *ngSwitchCase="UserNotificationType.EVENT_APPLICATION_WAITING_LIST" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}" 
                    [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id)" matRipple>
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your application is on waiting list</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </a> 
                
                <a *ngSwitchCase="UserNotificationType.EVENT_APPLICATION_CANCELED" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}" 
                    [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id)" matRipple>
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your application has been canceled</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </a>

                <a *ngSwitchCase="UserNotificationType.EVENT_APPLICATION_DECLINED" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}" 
                    [routerLink]="NavigationService.EventRoutes.Teammate.Home(notification.event_id)" matRipple>
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your application has been declined</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </a>

                <div *ngSwitchCase="UserNotificationType.EVENT_APPLICATION_DELETED" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}">
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your application has been deleted</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </div>

                <a *ngSwitchCase="UserNotificationType.EVENT_PLANNING_UPDATED" class="flex p-2 color-inherit border-b border-qo-divider dark:border-gray-700 last:border-0" [ngClass]="{'bg-qo-primary/20 font-medium' : !notification.read}"
                    [routerLink]="NavigationService.EventRoutes.Teammate.Activities(notification.event_id)" matRipple>
                    <div class="flex bg-qo-light dark:bg-qo-dark-600  rounded-md h-12 overflow-hidden mr-2 w-12" [matTooltip]="notification?.event_name">
                        <img class="block h-full w-full object-cover" *ngIf="notification.thumbnail_asset_key" [src]="notification.thumbnail_asset_key|toAssetUrl">
                        <mat-icon *ngIf="!notification.thumbnail_asset_key" class="material-icons-outlined m-auto">notifications</mat-icon>
                    </div>
                    <div >
                        <span i18n>Your schedule has been updated</span>
                        <br /><em class="text-sm not-italic opacity-60">{{notification.timestamp|moment:"lll"}}</em>
                    </div>
                </a>
            </ng-container>
        
        </ng-container>

        <div *ngIf="(dataService.$datasource|async)?.length == 0">
            <p class="font-medium text-center m-0 py-8">Aucune notification</p>
        </div>

    </div>
</mde-popover>